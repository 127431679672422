import { axiosInstance } from "../../axios";

const POSTSalesReportByProduct = "/api/ReportSalesByProduct/ReportSalesByProduct";
const POSTSalesReportExportProduct = "/api/ReportSalesByProduct/ExportToExcel";
const POSTSalesReportExportToPDFProduct = "/api/ReportSalesByProduct/ExportToPDF";

const POSTCustomerReport = "/api/ReportCustomerData/ReportCustomerData";
const POSTCustomerReportExportProduct = "/api/ReportCustomerData/ExportToPDF";
const POSTCustomerReportExportToPDFProduct = "/api/ReportCustomerData/ExportToExcel";

const POSTVisitHistoryReport = "/api/ReportVisitHistory/ReportVisitHistoryData";
const POSTVisitHistoryReportExportProduct = "/api/ReportVisitHistory/ExportToExcel";

const POSTSeasonPassByPostcodeReportExportProduct = "api/ReportSeasonPassByPostcode/ExportToExcel"

const salesReportByProduct = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSalesReportByProduct, data);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const exportToExcelSalesReportByProduct = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSalesReportExportProduct, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const exportToPdfSalesReportByProduct = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSalesReportExportToPDFProduct, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const customerReport = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTCustomerReport, data);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const customerReportExportToPdf = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTCustomerReportExportProduct, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const customerReportExportToExcel = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTCustomerReportExportToPDFProduct, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const visitHistoryReport = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTVisitHistoryReport, data);
        return parseList(response);
    } catch (error) {
        return null;
    }
};

const visitHistoryReportExportToExcel = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTVisitHistoryReportExportProduct, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const seasonPassByPostcodeReportExportProduct = async function(data) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSeasonPassByPostcodeReportExportProduct, data);
        return response.data;
    } catch (error) {
        return null;
    }
};

const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);

    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const reportsData = {
    salesReportByProduct,
    exportToExcelSalesReportByProduct,
    exportToPdfSalesReportByProduct,
    customerReport,
    customerReportExportToPdf,
    customerReportExportToExcel,
    visitHistoryReport,
    visitHistoryReportExportToExcel,
    seasonPassByPostcodeReportExportProduct,
};