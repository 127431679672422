<template>
<div class="vx-row">
    <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <vx-card title="Filter">
            <div class="filter-container">
                <h6 class="font-bold mb-3">Start Date</h6>
                <div>
                    <flat-pickr v-model="fromDate" placeholder="From Date" @on-change="onFromChange" class="w-full" />
                </div>

                <vs-divider />

                <h6 class="font-bold mb-4">End Date</h6>
                <div>
                    <flat-pickr v-model="toDate" placeholder="To Date" @on-change="onToChange" class="w-full" />
                </div>

                <vs-divider />

                <h6 class="font-bold mb-4">Select Product</h6>
                <div>
                    <vs-select name="location" v-model="selectedProduct" @change="generateReport" class="w-full">
                        <vs-select-item key="00000000-0000-0000-0000-000000000000" value="00000000-0000-0000-0000-000000000000" text="All Products" />
                        <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item, index) in productList" />
                    </vs-select>
                </div>

                <vs-divider />

                <div class="flex justify-center">
                    <vs-button class="w-full" slot-scope="{ canRefine, refine }" @click.prevent="refine" :disabled="!canRefine">Remove Filters</vs-button>
                </div>
            </div>
        </vx-card>
    </div>
    <div class="vx-col md:w-3/4 sm:w-1/2 w-full">
        <div class="relative mb-2">
            <div class="vx-row">
                <div class="vx-col md:w-1/3 sm:w-1/3 w-full">
                    <statistics-card-line hideChart icon="CreditCardIcon" :statistic="productList.length" statisticTitle="Active Products" />
                </div>
                <div class="vx-col md:w-1/3 sm:w-1/3 w-full">
                    <statistics-card-line hideChart icon="CreditCardIcon" :statistic="totalPassCount" statisticTitle="Total Passes" />
                </div>
                <div class="vx-col md:w-1/3 sm:w-1/3 w-full">
                    <statistics-card-line hideChart icon="DollarSignIcon" :statistic="totalAmount | formatCurrency" statisticTitle="Total Amount" />
                </div>
            </div>
        </div>

        <vx-card class="item-details mb-base welcomeCard" title="Sales By Product">
            <template slot="actions">
                <vs-button color="danger" @click="downloadPdf">Report</vs-button>
                <vs-button class="ml-2" color="success" @click="downloadexcel">Export</vs-button>
            </template>
            <vs-table stripe :data="reportDataList">
                <template #thead>
                    <vs-th>Date</vs-th>
                    <vs-th>Product</vs-th>
                    <vs-th>Sold Passes</vs-th>
                    <vs-th>Total Amount</vs-th>
                </template>
                <template>
                    <vs-tr :key="i" v-for="(tr, i) in reportDataList" :data="tr">
                        <vs-td>{{ tr.transactionDate | formatLongDate }}
                        </vs-td>
                        <vs-td>
                            {{ tr.productName }}
                        </vs-td>
                        <vs-td>
                            {{ tr.countSold }}
                        </vs-td>
                        <vs-td>
                            {{ tr.totalAmount | formatCurrency }}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    customMessages
} from "./../../filters/validationmessage";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from 'moment'

import {
    reportsData
} from "@/store/api/report";

import {
    productData
} from "@/store/api/product";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'

export default {
    components: {
        flatPickr,
        VuePerfectScrollbar,
        StatisticsCardLine
    },
    data() {
        return {
            customMessages,
            selectedProduct: "00000000-0000-0000-0000-000000000000",
            startTime: null,
            endTime: null,
            isResourceLoaded: true,
            fromDate: null,
            toDate: null,
            productList: [],
            reportDataList: [],
            isFilterSidebarActive: true,
            configFromdateTimePicker: {
                maxDate: null,
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            },
            configTodateTimePicker: {
                minDate: null,
                altFormat: 'd-m-Y',
                altInput: true,
                dateFormat: 'Y-m-d',
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.7
            }
        };
    },
    async created() {
        this.fromDate = moment().add(-7, 'days').format();
        this.toDate = moment().format();
        await this.loadProduct();
        await this.generateReport();
    },
    computed: {
        scrollbarTag() {
            return this.$store.getters.scrollbarTag;
        },
        totalPassCount() {
            let value = "0";
            if (this.reportDataList.length > 0) {
                value = this.reportDataList.reduce((acc, item) => acc + item.countSold, 0);
            }
            return value;
        },
        totalAmount() {
            let value = "0";
            if (this.reportDataList.length > 0) {
                value = this.reportDataList.reduce((acc, item) => acc + item.totalAmount, 0);
            }
            return value;
        }
    },
    methods: {
        async loadProduct() {
            this.productList = await productData.SearchProducts();
        },
        async downloadPdf() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            let data = await reportsData.exportToPdfSalesReportByProduct(reportParameter);
            window.open(data.url, '_blank').focus();
        },
        async downloadexcel() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            let data = await reportsData.exportToExcelSalesReportByProduct(reportParameter);
            window.open(data.url, '_blank').focus();
        },
        async generateReport() {
            const reportParameter = {
                startDate: this.fromDate,
                endDate: this.toDate,
                productId: this.selectedProduct
            };

            this.reportDataList = await reportsData.salesReportByProduct(reportParameter);
            const value = this.reportDataList.reduce((acc, item) => acc + item.countSold, 0);
        },
        async onFromChange(selectedDates, dateStr, instance) {
            this.$set(this.configTodateTimePicker, "minDate", dateStr);
            const date = new Date(dateStr);
            await this.generateReport();
        },
        async onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
            await this.generateReport();
        },
    }
};
</script>
